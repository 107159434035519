<template>
  <div class="container-fluid full-height">
    <div class="row full-height align-items-center">
      <div class="col-12 col-md-6 d-flex flex-column align-items-center">
        <img src="../../assets/logo-cordier-small.jpg" alt="Cordier logo" />
        <small class="text-muted">v{{pckg.version}}</small>
        <small class="text-muted text-center mt-3">Développé par Corellia - <a href="mailto:info@corellia.be">info@corellia.be</a></small>
      </div>
      <div class="col-12 col-md-6 px-5">
        <h1>Connexion</h1>
        <form @submit="handleLogin($event)">
          <div v-if="!isOnline" class="bg-warning p-2 small mb-2 rounded">Application hors ligne</div>
          <div v-if="error" class="bg-danger text-white p-2 small mb-2 rounded">{{error}}</div>
          <div class="form-group">
            <label for="login">Identifiant</label>
            <input type="text" class="form-control" id="login" v-model="login" required :disabled="isLoading" />
          </div>
          <div class="form-group">
            <label for="password">Mot de passe</label>
            <input type="password" class="form-control" id="password" v-model="password" required :disabled="isLoading" />
          </div>
          <button type="submit" class="btn btn-danger bg-cordier" :disabled="isLoading" v-html="loadingState"></button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AppService from '../../shared/services/AppService';

const pckg = require('../../../package.json');

export default {
  name: 'Login',
  data() {
    return {
      pckg,
      login: '',
      password: '',
      error: null,
      isLoading: false,
    };
  },
  methods: {
    async handleLogin(e) {
      this.isLoading = true;
      e.preventDefault();
      this.error = null;

      if (e.target.checkValidity() && this.isOnline) {
        const response = await AppService.login(this.login, this.password);
        try {
          if (response.status === 200) {
            this.$store.commit('setUser', response.data);
            if (this.isSuperAdmin) {
              this.$router.replace('/admin');
            } else {
              this.$router.replace(this.isAdmin ? '/admin-exports' : '/customers');
            }
          } else if (response.status === 404) {
            this.error = 'Combinaison identifiant/mot de passe incorrecte';
          } else {
            this.error = 'Une erreur inattendue s\'est produite lors de l\'authentification';
          }
        } catch (err) {
          this.error = 'Une erreur inattendue s\'est produite avec le serveur';
        }
      }
      if (!this.isOnline) {
        this.error = 'Nous ne pouvons vous authentifier hors connexion';
      }
      this.isLoading = false;
    },
  },
  computed: {
    isAdmin() {
      return this.$store.state.isAdmin === 1 || this.$store.state.isAdmin === 2;
    },
    isSuperAdmin() {
      return this.$store.state.isAdmin === 2;
    },
    isOnline() {
      return this.$store.state.isOnline;
    },
    loadingState() {
      return this.isLoading ? '<span class="loader-sm">&nbsp;</span>' : 'Se connecter';
    },
  },
};
</script>
