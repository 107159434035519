<template>
  <tr>
    <td class="small">{{localItem.cordierId}}</td>
    <td class="small">{{localItem.name}}</td>
    <td class="small">{{localItem.supplierRef}}</td>
    <td class="small text-right text-nowrap">{{localItem.prixIndicatif | floatToString('€')}}</td>
    <td class="small text-right text-nowrap">{{localItem.ristourne | floatToString('€')}}</td>
    <td v-for="i of 6" :key="i" class="small weeks_cell">
      <span class="form-control table-input d-flex align-items-center justify-content-center" :class="{ 'bg-cordier text-white': isActive && (activeItemIndex === i), 'border-danger': ristournes[i - 1] === 'N','border-success': ristournes[i - 1] === 'O' }" v-html="localItem[`qtyWeek${i}`] ? localItem[`qtyWeek${i}`] : 0" @click="handleClick(localItem, i)" @touchstart="handleLongpressAction(i)" @touchend="stopLongpressAction()"></span>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'OrderItemLine',
  props: ['item', 'activeItem', 'activeItemIndex', 'activeItemValue'],
  data() {
    return {
      localItem: this.item,
      mousePress: null,
    };
  },
  watch: {
    item(val) {
      this.localItem = val;
    },
  },
  methods: {
    handleLongpressAction(index) {
      this.mousePress = setTimeout(() => this.spreadValue(index), 500);
    },
    stopLongpressAction() {
      clearTimeout(this.mousePress);
    },
    spreadValue(index) {
      this.$emit('onSpread', { item: this.localItem, index, value: this.localItem[`qtyWeek${index}`] });
    },
    handleClick(item, index) {
      if (this.isActive && (this.activeItemIndex === index)) {
        this.$emit('onSpread', { item: null, index: null, value: null });
      } else if (this.activeItem) {
        this.localItem[`qtyWeek${index}`] = this.activeItemValue;
        this.$forceUpdate();
      } else {
        this.$emit('onNewValueReq', { item, index });
      }
    },
  },
  computed: {
    isActive() {
      return this.activeItem === this.localItem;
    },
    ristournes() {
      return this.localItem.periodeRistourne.split('');
    },
  },
};
</script>
