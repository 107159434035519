<template>
  <tr>
    <td class="small py-2">{{customer.name}}</td>
    <td class="small py-2">{{customer.address}} - {{customer.postalCode}} {{customer.city}}</td>
    <td class="small py-2">{{customer.vat}}</td>
    <td class="small py-2">{{qtyTotal}}</td>
    <td class="small py-2">
      <template v-if="isOnline && isProcessing">
        <span>Envoi en cours...</span>
        <span class="loader-sm">&nbsp;</span>
      </template>
      <template v-else>
        <span class="text-muted">En attente</span>
      </template>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'CartOrderLine',
  props: ['order', 'index'],
  computed: {
    customer() {
      return this.$store.state.customers.find((el) => el.visitorId === this.order.fkVisitor);
    },
    qtyTotal() {
      let total = 0;
      this.order.articles.forEach((item) => {
        total += item.qtyWeek1 ? parseInt(item.qtyWeek1, 10) : 0;
        total += item.qtyWeek2 ? parseInt(item.qtyWeek2, 10) : 0;
        total += item.qtyWeek3 ? parseInt(item.qtyWeek3, 10) : 0;
        total += item.qtyWeek4 ? parseInt(item.qtyWeek4, 10) : 0;
        total += item.qtyWeek5 ? parseInt(item.qtyWeek5, 10) : 0;
        total += item.qtyWeek6 ? parseInt(item.qtyWeek6, 10) : 0;
      });
      return total;
    },
    isOnline() {
      return this.$store.state.isOnline;
    },
    isProcessing() {
      return this.$store.state.processingOrderIndex === this.index;
    },
  },
};
</script>
