import axios from 'axios';
import Store from '../../store';

const getBaseUrl = (hostname) => {
  switch (hostname) {
    case 'cordier-foire-staging.azurewebsites.net':
      return 'https://cordier-foire-staging.azurewebsites.net';
    case 'cordier-foire.azurewebsites.net':
      return 'https://cordier-foire.azurewebsites.net';
    case 'cordier.shopscan.be':
      return 'https://cordier.shopscan.be';
    default:
      return '';
  }
};
axios.defaults.baseURL = getBaseUrl(window.location.hostname);
export default class AppService {
  /* Articles */
  static getArticles(supplierID) {
    const config = {
      headers: {
        'X-User-Token': Store.state.user.token,
        'X-User-Supplier': Store.state.user.supplierId,
        'X-User-IsAdmin': Store.state.user.isAdmin,
      },
    };
    return axios.get(`/api/Article/${supplierID}`, config)
      .then((response) => response).catch((err) => err.response);
  }

  /* Document */
  static getCheque(visitorId) {
    const config = {
      headers: {
        'X-User-Token': Store.state.user.token,
        'X-User-Supplier': Store.state.user.supplierId,
        'X-User-IsAdmin': Store.state.user.isAdmin,
      },
    };
    return axios.get(`/api/Document/getCheque?visitorID=${visitorId}`, config)
      .then((response) => response).catch((err) => err.response);
  }

  static sendCheque(email, visitorId) {
    const config = {
      headers: {
        'X-User-Token': Store.state.user.token,
        'X-User-Supplier': Store.state.user.supplierId,
        'X-User-IsAdmin': Store.state.user.isAdmin,
      },
    };
    return axios.get(`/api/Document/sendEmail?visitorID=${visitorId}&Email=${email}`, config)
      .then((response) => response).catch((err) => err.response);
  }

  static getXLSorders(supplierId) {
    const config = {
      headers: {
        'X-User-Token': Store.state.user.token,
        'X-User-Supplier': Store.state.user.supplierId,
        'X-User-IsAdmin': Store.state.user.isAdmin,
      },
    };
    return axios.get(`/api/Document/getXLSorders?supplierId=${supplierId}`, config)
      .then((response) => response).catch((err) => err.response);
  }

  static getPDForders(supplierId) {
    const config = {
      headers: {
        'X-User-Token': Store.state.user.token,
        'X-User-Supplier': Store.state.user.supplierId,
        'X-User-IsAdmin': Store.state.user.isAdmin,
      },
    };
    return axios.get(`/api/Document/getSupplierOrder?supplierId=${supplierId}`, config)
      .then((response) => response).catch((err) => err.response);
  }

  static getPasswords() {
    const config = {
      headers: {
        'X-User-Token': Store.state.user.token,
        'X-User-Supplier': Store.state.user.supplierId,
        'X-User-IsAdmin': Store.state.user.isAdmin,
      },
    };
    return axios.get('/api/Document/getPasswords', config)
      .then((response) => response).catch((err) => err.response);
  }

  static exportOrders() {
    const config = {
      headers: {
        'X-User-Token': Store.state.user.token,
        'X-User-Supplier': Store.state.user.supplierId,
        'X-User-IsAdmin': Store.state.user.isAdmin,
      },
    };
    return axios.get('/api/Document/ExportAllOrders', config)
      .then((response) => response).catch((err) => err.response);
  }

  /* Files */
  static uploadFiles(files, type) {
    const config = {
      headers: {
        'X-User-Token': Store.state.user.token,
        'X-User-Supplier': Store.state.user.supplierId,
        'X-User-IsAdmin': Store.state.user.isAdmin,
      },
    };
    return axios.post(`/api/File/upload?type=${type}`, files, config)
      .then((response) => response).catch((err) => err.response);
  }

  static getStatQty() {
    const config = {
      headers: {
        'X-User-Token': Store.state.user.token,
        'X-User-Supplier': Store.state.user.supplierId,
        'X-User-IsAdmin': Store.state.user.isAdmin,
      },
    };
    return axios.get('/api/File/statQty', config)
      .then((response) => response).catch((err) => err.response);
  }

  static resetDB() {
    const config = {
      headers: {
        'X-User-Token': Store.state.user.token,
        'X-User-Supplier': Store.state.user.supplierId,
        'X-User-IsAdmin': Store.state.user.isAdmin,
      },
    };
    return axios.get('/api/File/resetDB', config)
      .then((response) => response).catch((err) => err.response);
  }

  /* Orders */
  static createOrder(command) {
    const config = {
      headers: {
        'X-User-Token': Store.state.user.token,
        'X-User-Supplier': Store.state.user.supplierId,
        'X-User-IsAdmin': Store.state.user.isAdmin,
      },
    };
    return axios.post('/api/Order/CreateOrder', command, config)
      .then((response) => response).catch((err) => err.response);
  }

  static getRistourne(command) {
    const config = {
      headers: {
        'X-User-Token': Store.state.user.token,
        'X-User-Supplier': Store.state.user.supplierId,
        'X-User-IsAdmin': Store.state.user.isAdmin,
      },
    };
    return axios.post('/api/Order/getRistourne', command, config)
      .then((response) => response).catch((err) => err.response);
  }

  static getOrder(orderId) {
    const config = {
      headers: {
        'X-User-Token': Store.state.user.token,
        'X-User-Supplier': Store.state.user.supplierId,
        'X-User-IsAdmin': Store.state.user.isAdmin,
      },
    };
    return axios.get(`/api/Order/getOrder/${orderId}`, config)
      .then((response) => response).catch((err) => err.response);
  }

  static getOrderSummary(visitorId, supplierId) {
    const config = {
      headers: {
        'X-User-Token': Store.state.user.token,
        'X-User-Supplier': Store.state.user.supplierId,
        'X-User-IsAdmin': Store.state.user.isAdmin,
      },
    };
    return axios.get(`/api/Order/getOrderSummary/${visitorId}/${supplierId}`, config)
      .then((response) => response).catch((err) => err.response);
  }

  static getOrderList() {
    const config = {
      headers: {
        'X-User-Token': Store.state.user.token,
        'X-User-Supplier': Store.state.user.supplierId,
        'X-User-IsAdmin': Store.state.user.isAdmin,
      },
    };
    return axios.get('/api/Order/getOrderList', config)
      .then((response) => response).catch((err) => err.response);
  }

  static getOrderStat(date) {
    const config = {
      headers: {
        'X-User-Token': Store.state.user.token,
        'X-User-Supplier': Store.state.user.supplierId,
        'X-User-IsAdmin': Store.state.user.isAdmin,
      },
    };
    return axios.get(`/api/Order/getOrderStat/${date}`, config)
      .then((response) => response).catch((err) => err.response);
  }

  /* Suppliers */
  static login(user, password) {
    return axios.post(`/api/Supplier/Logon?login=${user}&password=${password}`)
      .then((response) => response).catch((err) => err.response);
  }

  static getSupplier(supplierName) {
    const config = {
      headers: {
        'X-User-Token': Store.state.user.token,
        'X-User-Supplier': Store.state.user.supplierId,
        'X-User-IsAdmin': Store.state.user.isAdmin,
      },
    };
    return axios.get(`/api/Supplier/${supplierName}`, config)
      .then((response) => response).catch((err) => err.response);
  }

  /* Visitors */
  static getCustomer(customer) {
    const config = {
      headers: {
        'X-User-Token': Store.state.user.token,
        'X-User-Supplier': Store.state.user.supplierId,
        'X-User-IsAdmin': Store.state.user.isAdmin,
      },
    };
    return axios.get(`/api/Visitor/getVisitor/?visitorNumber=${customer}`, config)
      .then((response) => response).catch((err) => err.response);
  }

  /* Params */
  static getParamEmail() {
    const config = {
      headers: {
        'X-User-Token': Store.state.user.token,
        'X-User-Supplier': Store.state.user.supplierId,
        'X-User-IsAdmin': Store.state.user.isAdmin,
      },
    };
    return axios.get('/api/Param/getParamEmail', config)
      .then((response) => response).catch((err) => err.response);
  }

  static updateParamEmail(command) {
    const config = {
      headers: {
        'X-User-Token': Store.state.user.token,
        'X-User-Supplier': Store.state.user.supplierId,
        'X-User-IsAdmin': Store.state.user.isAdmin,
      },
    };
    return axios.post('/api/Param/updateParamEmail', command, config)
      .then((response) => response).catch((err) => err.response);
  }
}
