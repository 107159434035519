import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isOnline: navigator.onLine,
    isAdmin: 0,
    user: null,
    lastCustomerFetch: null,
    customers: null,
    workingCustomer: null,
    lastArticlesFetch: null,
    articles: null,
    ordersToPush: [],
    processingOrderIndex: null,
    windowHeight: window.innerHeight,
  },
  mutations: {
    setOnlineState(state, payload) {
      state.isOnline = payload;
    },
    setUser(state, payload) {
      localStorage.setItem('user', JSON.stringify(payload));
      state.user = payload;
      state.isAdmin = payload.isAdmin;
    },
    setCustomers(state, { data, date }) {
      state.customers = data;
      state.lastCustomerFetch = date;
    },
    setWorkingCustomer(state, payload) {
      state.workingCustomer = payload;
    },
    setArticles(state, { data, date }) {
      state.articles = data;
      state.articles.forEach((item) => {
        item.qtyWeek1 = null;
        item.qtyWeek2 = null;
        item.qtyWeek3 = null;
        item.qtyWeek4 = null;
        item.qtyWeek5 = null;
        item.qtyWeek6 = null;
      });
      state.lastArticlesFetch = date;
    },
    pushNewOrder(state, payload) {
      state.ordersToPush.unshift(payload);
      localStorage.setItem('ordersToPush', JSON.stringify(state.ordersToPush));
    },
    removePushedOrder(state) {
      state.ordersToPush.pop();
      if (state.ordersToPush.length === 0) {
        localStorage.removeItem('ordersToPush');
      } else {
        localStorage.setItem('ordersToPush', JSON.stringify(state.ordersToPush));
      }
    },
    setProcessingOrderIndex(state, payload) {
      state.processingOrderIndex = payload;
    },
  },
  actions: {

  },
});
