<template>
  <div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header" :class="{ 'bg-danger text-white': toast.status === 'erreur', 'bg-success text-white': toast.status === 'succès', 'bg-warning text-dark': toast.status === 'attention'}">
      <strong class="mr-auto text-capitalize">{{toast.status}}</strong>
      <button type="button" class="ml-2 mb-1 close" aria-label="Close" @click="handleCloseToast(index)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="toast-body" v-html="toast.body"></div>
  </div>
</template>

<script>
export default {
  name: 'Toast',
  props: ['toast', 'index'],
  methods: {
    handleCloseToast(index) {
      this.$emit('onCloseToast', index);
    },
  },
  mounted() {
    if (this.toast.status === 'succès') {
      setTimeout(() => this.handleCloseToast(this.index), 5000);
    }
  },
};
</script>
