import Store from '../../../store';

export default class NewOrderCommand {
  constructor() {
    this.orderId = null;
    this.fkSupplier = Store.state.user.supplierId;
    this.fkVisitor = null;
    this.orderLines = JSON.parse(JSON.stringify(Store.state.articles));
    this.signature = null;
  }
}
