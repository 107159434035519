import Vue from 'vue';
import App from './App.vue';
import './assets/bootstrap.min.css';
import './assets/styles.styl';
import './registerServiceWorker';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

Vue.filter('floatToString', (value, suffix) => {
  if (!value) {
    return '0.00';
  }

  if (typeof value === 'string') {
    const hasSuffix = value.indexOf(' ') !== -1;
    return hasSuffix ? value : `${value} ${suffix}`;
  }

  const formatted = value.toFixed(2).toString(10);
  return suffix ? `${formatted} ${suffix}` : formatted;
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
