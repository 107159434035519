<template>
  <div class="container-fluid p-interface p-interface--bottom">
    <div class="row mb-3">
      <div class="col-12 mt-4 mb-2">
        <h2 class="mb-0">Nouvelle commande</h2>
        <span class="text-muted">{{customer.name}}</span>
      </div>
      <div class="col-12 col-sm-6">
        <form action="#" @submit.prevent="getFilteredArticles()">
          <div class="input-group mb-3">
            <input type="search" class="form-control" aria-label="Recherche" v-model="search" />
            <div class="input-group-append">
              <button class="btn btn-danger bg-cordier" type="submit">
                Rechercher
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row mb-3">
      <div v-if="filteredArticles.length > 0" class="col-12">
        <table class="table table-striped">
          <thead>
            <th class="small font-weight-bold bg-light">Référence</th>
            <th class="small font-weight-bold bg-light">Libellé</th>
            <th class="small font-weight-bold bg-light">Ref. Fournisseur</th>
            <th class="small font-weight-bold bg-light text-right">PVI</th>
            <th class="small font-weight-bold bg-light text-right">Ristourne</th>
            <!-- TODO: Update week numbers -->
            <th class="small font-weight-bold bg-light weeks_cell">41</th>
            <th class="small font-weight-bold bg-light weeks_cell">42</th>
            <th class="small font-weight-bold bg-light weeks_cell">43</th>
            <th class="small font-weight-bold bg-light weeks_cell">44</th>
            <th class="small font-weight-bold bg-light weeks_cell">45</th>
            <th class="small font-weight-bold bg-light weeks_cell">46</th>
          </thead>
          <tbody>
            <OrderItemLine v-for="item of filteredArticles" :key="item.articleId + item.qtyWeek1 + item.qtyWeek2 + item.qtyWeek3 + item.qtyWeek4 + item.qtyWeek5 + item.qtyWeek6" :item="item" :activeItem="activeItem" :activeItemIndex="activeItemIndex" :activeItemValue="activeItemValue" @onNewValueReq="showPadAction($event)" @onSpread="handleSpreadValue($event)" />
          </tbody>
        </table>
      </div>
      <div v-else class="col text-center">
        <template v-if="error">
          <p class="text-muted">{{error}}</p>
          <button class="btn btn-danger bg-cordier" @click="getArticles()">Réessayer</button>
        </template>
        <template v-if="isLoading">
          <div class="loader"></div>
        </template>
      </div>
    </div>
    <div v-if="!isShrinked" class="row bottom-actions w-100">
      <div class="col py-2 d-flex align-items-center">
        <button class="btn btn-outline-danger mr-3" @click="toggleCancelModal()">Annuler commande</button>
      </div>
      <div class="col py-2 d-flex justify-content-end align-items-center">
        <button class="btn btn-danger bg-cordier" @click="handleValidate()" :disabled="!hasOrdered">Valider</button>
      </div>
    </div>
    <div v-if="showCancelModal" class="overlay">
      <div class="modal rounded p-4">
        <div class="row">
          <div class="col">
            <span class="h3 mb-3 d-block">Êtes-vous sûr ?</span>
            <p class="mb-3 d-block">Cela annulera la commande en cours</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button class="btn btn-sm btn-secondary bg-dark mr-3" @click="toggleCancelModal()">Continuer la commande</button>
            <button class="btn btn-sm btn-danger bg-cordier" @click="handleCancel()">Annuler la commande</button>
          </div>
        </div>
      </div>
    </div>
    <InputPad v-if="showPad" :currentValue="itemToUpdateValue" :item="itemToUpdate" :index="itemWeekToUpdate" @onValueUpdate="showPadAction()" @onDismiss="showPadAction()" />
  </div>
</template>

<script>
import NewOrderCommand from './commands/NewOrder-command';
import InputPad from './components/InputPad.vue';
import OrderItemLine from './components/OrderItemLine.vue';

export default {
  name: 'NewOrder',
  components: {
    OrderItemLine,
    InputPad,
  },
  props: {
    editOrder: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
      search: '',
      newOrderCommand: null,
      showCancelModal: false,
      itemToUpdate: null,
      itemToUpdateValue: null,
      itemWeekToUpdate: null,
      showPad: false,
      activeItem: null,
      activeItemIndex: null,
      activeItemValue: null,
      isShrinked: false,
      filteredArticles: [],
      isCancelling: false,
    };
  },
  methods: {
    handleValidate() {
      this.$router.push({ name: 'confirm-order', params: { orderCommand: this.newOrderCommand } });
    },
    handleCancel() {
      this.isCancelling = true;
      this.$store.commit('setWorkingCustomer', null);
      this.$router.replace('/customers');
    },
    handleSpreadValue({ item, index, value }) {
      this.activeItem = item;
      this.activeItemIndex = index;
      this.activeItemValue = value;
      this.$forceUpdate();
    },
    toggleCancelModal() {
      this.showCancelModal = !this.showCancelModal;
    },
    showPadAction(obj = null) {
      this.itemToUpdate = obj ? obj.item : null;
      this.itemWeekToUpdate = obj ? obj.index : null;
      this.showPad = !this.showPad;
    },
    checkForKeyboard() {
      this.isShrinked = window.innerHeight < this.$store.state.windowHeight;
    },
    getFilteredArticles() {
      this.filteredArticles = [];
      if (this.newOrderCommand.orderLines) {
        this.newOrderCommand.orderLines.forEach((item) => {
          if (item.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || item.articleId.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) {
            this.filteredArticles.push(item);
          }
        });
      }
    },
  },
  computed: {
    customer() {
      return this.$store.state.workingCustomer;
    },
    user() {
      return this.$store.state.user;
    },
    isOnline() {
      return this.$store.state.isOnline;
    },
    articles() {
      return this.$store.state.articles;
    },
    hasOrdered() {
      const orderedArticles = [];
      this.newOrderCommand.orderLines.forEach((item) => {
        if (item.qtyWeek1 || item.qtyWeek2 || item.qtyWeek3 || item.qtyWeek4 || item.qtyWeek5 || item.qtyWeek6) {
          orderedArticles.push(item);
        }
      });
      return orderedArticles.length > 0;
    },
  },
  created() {
    if (!this.editOrder) {
      this.newOrderCommand = new NewOrderCommand();
      this.newOrderCommand.fkVisitor = this.customer.visitorId;
    } else {
      this.newOrderCommand = this.editOrder;
    }
    this.getFilteredArticles();
    window.addEventListener('resize', this.checkForKeyboard);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkForKeyboard);
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'customers' && !this.isCancelling) {
      this.toggleCancelModal();
      next(false);
    } else {
      next();
    }
  },
};
</script>
