import Vue from 'vue';
import Router from 'vue-router';
import Login from './views/login/Login.vue';
import Customers from './views/customers/Customers.vue';
import NewOrder from './views/customers/NewOrder.vue';
import ConfirmOrder from './views/customers/ConfirmOrder.vue';
import Cart from './views/cart/Cart.vue';
import Admin from './views/admin/Admin.vue';
import AdminExports from './views/admin/AdminExports.vue';
import Goods from './views/goods/Goods.vue';
import Suppliers from './views/suppliers/Suppliers.vue';
import Orders from './views/orders/Orders.vue';
import Visitors from './views/visitors/Visitors.vue';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/customers',
      name: 'customers',
      component: Customers,
    },
    {
      path: '/customers/new-order',
      name: 'new-order',
      component: NewOrder,
      props: true,
    },
    {
      path: '/customers/new-order/confirm',
      name: 'confirm-order',
      component: ConfirmOrder,
      props: true,
    },
    {
      path: '/cart',
      name: 'cart',
      component: Cart,
    },
    {
      path: '/admin',
      name: 'admin',
      component: Admin,
    },
    {
      path: '/goods',
      name: 'goods',
      component: Goods,
    },
    {
      path: '/suppliers',
      name: 'suppliers',
      component: Suppliers,
    },
    {
      path: '/orders',
      name: 'orders',
      component: Orders,
    },
    {
      path: '/visitors',
      name: 'visitors',
      component: Visitors,
    },
    {
      path: '/admin-exports',
      name: 'admin-exports',
      component: AdminExports,
    },
  ],
});
