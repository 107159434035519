<template>
  <div class="overlay">
    <div class="modal rounded">
      <div class="row no-gutters border-bottom">
        <div class="col p-3 text-right">
          <span class="display-4">{{result}}</span>
          <span class="d-block small text-danger" v-if="result === 100000">Maximum</span>
        </div>
      </div>
      <div class="row">
        <div class="col-4 d-flex align-items-center justify-content-center py-3 h3 pad-button" @click.stop="appendValue(1)">1</div>
        <div class="col-4 d-flex align-items-center justify-content-center py-3 h3 pad-button" @click.stop="appendValue(2)">2</div>
        <div class="col-4 d-flex align-items-center justify-content-center py-3 h3 pad-button" @click.stop="appendValue(3)">3</div>
        <div class="col-4 d-flex align-items-center justify-content-center py-3 h3 pad-button" @click.stop="appendValue(4)">4</div>
        <div class="col-4 d-flex align-items-center justify-content-center py-3 h3 pad-button" @click.stop="appendValue(5)">5</div>
        <div class="col-4 d-flex align-items-center justify-content-center py-3 h3 pad-button" @click.stop="appendValue(6)">6</div>
        <div class="col-4 d-flex align-items-center justify-content-center py-3 h3 pad-button" @click.stop="appendValue(7)">7</div>
        <div class="col-4 d-flex align-items-center justify-content-center py-3 h3 pad-button" @click.stop="appendValue(8)">8</div>
        <div class="col-4 d-flex align-items-center justify-content-center py-3 h3 pad-button" @click.stop="appendValue(9)">9</div>
        <div class="col-4 d-flex align-items-center justify-content-center py-3 h3 pad-button text-muted" @click.stop="clear()">C</div>
        <div class="col-4 d-flex align-items-center justify-content-center py-3 h3 pad-button" @click.stop="appendValue(0)">0</div>
        <div class="col-4 d-flex align-items-center justify-content-center py-3 h3 pad-button text-muted" @click.stop="backSpace()">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width: 1.75rem; height: 1.75rem; fill: currentColor"><path style="line-height:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;block-progression:tb;isolation:auto;mix-blend-mode:normal" d="M 6.109375 4 C 5.4242102 4 4.7749125 4.3597569 4.4121094 4.9394531 L 0 12 L 4.4140625 19.060547 C 4.7768656 19.640243 5.4242386 20 6.109375 20 L 21 20 C 22.094255 20 23 19.094255 23 18 L 23 6 C 23 4.9057453 22.094255 4 21 4 L 6.109375 4 z M 6.1074219 6 L 6.109375 6 L 21 6 L 21 18 L 6.109375 18 L 2.3574219 12 L 6.1074219 6 z M 9.7070312 7.2929688 L 8.2929688 8.7070312 L 11.585938 12 L 8.2929688 15.292969 L 9.7070312 16.707031 L 13 13.414062 L 16.292969 16.707031 L 17.707031 15.292969 L 14.414062 12 L 17.707031 8.7070312 L 16.292969 7.2929688 L 13 10.585938 L 9.7070312 7.2929688 z" font-weight="400" font-family="sans-serif" white-space="normal" overflow="visible"></path></svg>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-6">
          <button class="btn btn-lg btn-block btn-dark rounded-0" @click="dismiss()">Annuler</button>
        </div>
        <div class="col-6">
          <button class="btn btn-lg btn-block btn-danger bg-cordier rounded-0" @click="handleConfirm()">Valider</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputPad',
  props: ['item', 'index'],
  data() {
    return {
      localItem: this.item,
      result: 0,
    };
  },
  watch: {
    item(val) {
      this.localItem = val;
    },
  },
  methods: {
    dismiss() {
      this.$emit('onDismiss');
    },
    appendValue(value) {
      if (this.result === 0) {
        this.result = value;
      } else {
        const tmpResult = parseInt(this.result.toString() + value.toString(), 10);
        this.result = tmpResult > 100000 ? 100000 : tmpResult;
      }
    },
    clear() {
      this.result = 0;
    },
    backSpace() {
      this.result = this.result.toString().length === 1 ? 0 : parseInt(this.result.toString().substring(0, this.result.toString().length - 1), 10);
    },
    handleConfirm() {
      this.localItem[`qtyWeek${this.index}`] = this.result;
      this.$emit('onValueUpdate');
    },
  },
};
</script>
