<template>
  <div class="container-fluid p-interface p-interface--bottom">
    <template v-if="isLoading">
      <div class="row mt-5">
        <div class="col d-flex align-item-center justify-content-center mt-5">
          <span class="loader mt-5">Chargement des données</span>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row mb-3">
        <div class="col-12 mt-4 mb-2">
          <h2 class="mb-0">Articles</h2>
        </div>
        <div class="col-12 col-sm-6">
          <form action="#" @submit.prevent="getFilteredGoods()">
            <div class="input-group mb-3">
              <input type="search" class="form-control" aria-label="Recherche" v-model="search" />
              <div class="input-group-append">
                <button class="btn btn-danger bg-cordier" type="submit">
                  Rechercher
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <template v-if="goods.length > 0">
        <div v-if="filteredGoods.length > 0" class="row">
          <div class="col-12">
            <table class="table table-striped table-hover">
              <thead>
                <th class="small font-weight-bold bg-light">Reference</th>
                <th class="small font-weight-bold bg-light">Description</th>
                <th class="small font-weight-bold bg-light">Fournisseur</th>
                <th class="small font-weight-bold bg-light">Ref. Fournisseur</th>
                <th class="small font-weight-bold bg-light">Périodes ristourne</th>
                <th class="small font-weight-bold bg-light">Ristourne</th>
              </thead>
              <tbody>
                <tr v-for="(item, index) of filteredGoods" :key="item.articleId + index">
                  <td class="small py-2">{{item.articleId}}</td>
                  <td class="small py-2">{{item.name}}</td>
                  <td class="small py-2">{{item.supplier}}</td>
                  <td class="small py-2">{{item.supplierRef}}</td>
                  <td class="small py-2 d-flex flex-nowrap">
                    <span class="badge py-1 px-2 mr-1" :class="{ 'badge-success': item.periodeRistourne.split('')[0] === 'O', 'badge-danger': item.periodeRistourne.split('')[0] === 'N' }">41</span>
                    <span class="badge py-1 px-2 mr-1" :class="{ 'badge-success': item.periodeRistourne.split('')[1] === 'O', 'badge-danger': item.periodeRistourne.split('')[1] === 'N' }">42</span>
                    <span class="badge py-1 px-2 mr-1" :class="{ 'badge-success': item.periodeRistourne.split('')[2] === 'O', 'badge-danger': item.periodeRistourne.split('')[2] === 'N' }">43</span>
                    <span class="badge py-1 px-2 mr-1" :class="{ 'badge-success': item.periodeRistourne.split('')[3] === 'O', 'badge-danger': item.periodeRistourne.split('')[3] === 'N' }">44</span>
                    <span class="badge py-1 px-2 mr-1" :class="{ 'badge-success': item.periodeRistourne.split('')[4] === 'O', 'badge-danger': item.periodeRistourne.split('')[4] === 'N' }">45</span>
                    <span class="badge py-1 px-2 mr-1" :class="{ 'badge-success': item.periodeRistourne.split('')[5] === 'O', 'badge-danger': item.periodeRistourne.split('')[5] === 'N' }">46</span>
                  </td>
                  <td class="small py-2 text-nowrap">{{item.ristourne | floatToString('€')}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-12 text-center text-muted">
            <p>Aucun article ne correspond à votre recherche</p>
          </div>
        </div>
      </template>
      <template v-if="goods.length === 0 && !error">
        <div class="row">
          <div class="col-12 text-center text-muted">
            <p>Il semblerait que cette liste soit vide.<br>Importez une liste d'articles depuis le tableau de bord.</p>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row">
          <div class="col-12 text-center text-muted">
            <p>{{error}}</p>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import AppService from '../../shared/services/AppService';

export default {
  name: 'Goods',
  data() {
    return {
      search: null,
      goods: [],
      error: null,
      isLoading: false,
      filteredGoods: [],
    };
  },
  methods: {
    async getGoods() {
      this.isLoading = true;
      if (this.isOnline) {
        try {
          const response = await AppService.getArticles(encodeURIComponent('%'));

          if (response.status === 200) {
            this.goods = response.data;
            this.error = null;
          } else if (response.status === 404) {
            this.goods = [];
          } else {
            this.goods = [];
            this.error = 'Une erreur est survenue lors de la récupération des articles';
          }
        } catch (err) {
          this.goods = [];
          this.error = 'Une erreur innatendue est survenue avec notre serveur';
        }
      } else {
        this.goods = [];
        this.error = 'Impossible de récupérer les articles en mode hors-ligne';
      }
      this.isLoading = false;
    },
    getFilteredGoods() {
      this.filteredGoods = this.search ? this.goods.filter((el) => el.articleId.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || el.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || el.supplier.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) : this.goods;
    },
  },
  computed: {
    // filteredGoods() {
    //   return this.search ? this.goods.filter(el => el.articleId.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || el.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || el.supplier.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) : this.goods;
    // },
    isOnline() {
      return this.$store.state.isOnline;
    },
  },
  async created() {
    await this.getGoods();
    this.getFilteredGoods();
  },
};
</script>
