<template>
  <div class="container-fluid p-interface p-interface--bottom">
    <template v-if="isLoading">
      <div class="row mt-5">
        <div class="col d-flex align-item-center justify-content-center mt-5">
          <span class="loader mt-5">Chargement des données</span>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row mb-3">
        <div class="col-12 mt-4 mb-2">
          <h2 class="mb-0">Fournisseurs</h2>
        </div>
        <div class="col-12 col-sm-6">
          <form action="#" @submit.prevent="getFilteredSuppliers()">
            <div class="input-group mb-3">
              <input type="search" class="form-control" aria-label="Recherche" v-model="search" />
              <div class="input-group-append">
                <button class="btn btn-danger bg-cordier" type="submit">
                  Rechercher
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-sm-6 text-right">
          <button class="btn btn-danger bg-cordier" @click="getPasswords()"><span v-if="!isLoadingPasswords">Exporter mots de passe</span><span v-else class="loader-sm">&nbsp;</span></button>
        </div>
      </div>
      <template v-if="suppliers.length > 0">
        <div v-if="filteredSuppliers.length > 0" class="row">
          <div class="col-12">
            <table class="table table-striped table-hover">
              <thead>
                <th class="small font-weight-bold bg-light">Id</th>
                <th class="small font-weight-bold bg-light">Nom</th>
                <th class="small font-weight-bold bg-light">Login</th>
                <th class="small font-weight-bold bg-light">Mot de passe</th>
                <th class="small font-weight-bold bg-light">Nombre de commandes</th>
                <th class="small font-weight-bold bg-light">Action</th>
              </thead>
              <tbody>
                <tr v-for="item of filteredSuppliers" :key="item.supplierId">
                  <td class="small py-2">{{item.supplierId}}</td>
                  <td class="small py-2">{{item.name}}</td>
                  <td class="small py-2">{{item.login}}</td>
                  <td class="small py-2">{{item.password}}</td>
                  <td class="small py-2">{{item.nbrCmd}}</td>
                  <td class="small py-2">
                    <button v-if="item.nbrCmd > 0" class="btn mr-3 btn-icon btn-icon--large p-0" @click="getOrdersSummaryXLS(item.supplierId)">
                      <span v-if="loadingSupplierIdXLS === item.supplierId" class="loader-sm">&nbsp;</span>
                      <span v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24pt" height="24pt" viewBox="0 0 24 24" version="1.1"><g id="surface527446"><path style=" stroke:none;fill-rule:nonzero;fill:rgb(18.039216%,80%,44.313725%);fill-opacity:1;" d="M 6 2 C 4.90625 2 4 2.90625 4 4 L 4 9 C 2.894531 9 2 9.894531 2 11 L 2 16 C 2 17.105469 2.894531 18 4 18 L 4 20 C 4 21.09375 4.90625 22 6 22 L 18 22 C 19.09375 22 20 21.09375 20 20 L 20 7 L 15 2 Z M 6 4 L 14 4 L 14 8 L 18 8 L 18 9 L 6 9 Z M 4.023438 11 L 5.273438 11 L 5.996094 12.714844 L 6.722656 11 L 7.96875 11 L 6.703125 13.480469 L 8 16 L 6.738281 16 L 5.996094 14.253906 L 5.261719 16 L 4 16 L 5.292969 13.480469 Z M 9 11 L 10 11 L 10 15 L 12 15 L 12 16 L 9 16 Z M 14.492188 11 C 15.914062 11.039062 16 12.28125 16 12.503906 L 15.03125 12.503906 C 15.03125 12.402344 15.046875 11.804688 14.472656 11.804688 C 14.296875 11.804688 13.90625 11.882812 13.9375 12.367188 C 13.964844 12.808594 14.546875 13.019531 14.652344 13.066406 C 14.878906 13.148438 15.988281 13.640625 15.996094 14.648438 C 16 14.863281 15.941406 15.984375 14.507812 16 C 12.949219 16.015625 12.84375 14.675781 12.84375 14.398438 L 13.820312 14.398438 C 13.820312 14.546875 13.832031 15.257812 14.511719 15.199219 C 14.917969 15.167969 15.003906 14.875 15.019531 14.65625 C 15.042969 14.289062 14.691406 14.070312 14.324219 13.890625 C 13.800781 13.640625 12.980469 13.332031 12.964844 12.359375 C 12.953125 11.480469 13.597656 10.976562 14.492188 11 Z M 6 18 L 18 18 L 18 20 L 6 20 Z M 6 18 " /></g></svg>
                      </span>
                    </button>
                    <button v-if="item.nbrCmd > 0" class="btn mr-3 btn-icon btn-icon--large p-0" @click="getOrdersSummaryPDF(item.supplierId)">
                      <span v-if="loadingSupplierIdPDF === item.supplierId" class="loader-sm">&nbsp;</span>
                      <span v-else>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24pt" height="24pt" viewBox="0 0 172 172"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#e74c3c"><path d="M43,14.33333c-7.84216,0 -14.33333,6.49117 -14.33333,14.33333v35.83333c-7.91917,0 -14.33333,6.41417 -14.33333,14.33333v35.83333c0,7.91917 6.41417,14.33333 14.33333,14.33333v14.33333c0,7.84216 6.49117,14.33333 14.33333,14.33333h86c7.84216,0 14.33333,-6.49117 14.33333,-14.33333v-93.16667l-35.83333,-35.83333zM43,28.66667h57.33333v28.66667h28.66667v7.16667h-86zM28.66667,78.83333h10.75c5.934,0 10.75,4.816 10.75,10.75c0,5.934 -4.816,10.75 -10.75,10.75h-3.58333v14.33333h-7.16667zM93.16667,78.83333h21.5v7.16667h-14.33333v7.16667h11.46386v7.16667h-11.46386v14.33333h-7.16667zM57.33333,79.00131h11.67383c11.33767,0 16.99284,6.81885 16.99284,15.08919v5.52898c0,8.37067 -5.66716,15.0472 -17.09082,15.0472h-11.57585zM35.83333,86v7.16667h3.58333c1.978,0 3.58333,-1.60533 3.58333,-3.58333c0,-1.978 -1.60533,-3.58333 -3.58333,-3.58333zM64.5,86.16797v21.33203h4.40918c4.52933,0 9.92415,-1.3732 9.92415,-7.88053v-5.52898c0,-6.55033 -5.33984,-7.92252 -9.82617,-7.92252zM43,129h86v14.33333h-86z"></path></g></g></svg>
                      </span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-12 text-center text-muted">
            <p>Aucun fournisseur ne correspond à votre recherche</p>
          </div>
        </div>
      </template>
      <template v-if="suppliers.length === 0 && !error">
        <div class="row">
          <div class="col-12 text-center text-muted">
            <p>Il semblerait que cette liste soit vide.<br>Importez une liste de fournisseurs depuis le tableau de bord.</p>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row">
          <div class="col-12 text-center text-muted">
            <p>{{error}}</p>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import AppService from '../../shared/services/AppService';

export default {
  name: 'Suppliers',
  data() {
    return {
      search: null,
      suppliers: [],
      error: null,
      isLoading: false,
      loadingSupplierIdXLS: null,
      loadingSupplierIdPDF: null,
      isLoadingPasswords: false,
      filteredSuppliers: [],
    };
  },
  methods: {
    async getSuppliers() {
      this.isLoading = true;
      if (this.isOnline) {
        try {
          const response = await AppService.getSupplier(encodeURIComponent('%'));

          if (response.status === 200) {
            this.suppliers = response.data;
            this.error = null;
          } else {
            this.suppliers = [];
            this.error = 'Une erreur est survenue lors de la récupération des fournisseurs';
          }
        } catch (err) {
          this.suppliers = [];
          this.error = 'Une erreur innatendue est survenue avec notre serveur';
        }
      } else {
        this.suppliers = [];
        this.error = 'Impossible de récupérer les fournisseurs en mode hors-ligne';
      }
      this.isLoading = false;
    },
    async getOrdersSummaryXLS(id) {
      if (this.isOnline) {
        this.loadingSupplierIdXLS = id;
        try {
          const response = await AppService.getXLSorders(id);
          if (response.status === 200) {
            const bytes = await this.base64ToArrayBuffer(response.data);
            const blob = new Blob([bytes], { type: '"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${id}.xlsx`;
            link.click();
          } else {
            this.$emit('onToast', { status: 'erreur', body: 'Une erreur s\'est produite lors de la récupération du résumé des commandes' });
          }
        } catch (err) {
          this.$emit('onToast', { status: 'erreur', body: 'Une erreur s\'est produite lors de la récupération du résumé des commandes' });
        }
      } else {
        this.$emit('onToast', { status: 'attention', body: 'Impossible de récupérer le résumé des commandes en mode hors-ligne' });
      }
      this.loadingSupplierIdXLS = null;
    },
    async getOrdersSummaryPDF(id) {
      if (this.isOnline) {
        this.loadingSupplierIdPDF = id;
        try {
          const response = await AppService.getPDForders(id);
          if (response.status === 200) {
            const bytes = await this.base64ToArrayBuffer(response.data);
            const blob = new Blob([bytes], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${id}.pdf`;
            link.click();
          } else {
            this.$emit('onToast', { status: 'erreur', body: 'Une erreur s\'est produite lors de la récupération du résumé des commandes' });
          }
        } catch (err) {
          this.$emit('onToast', { status: 'erreur', body: 'Une erreur s\'est produite lors de la récupération du résumé des commandes' });
        }
      } else {
        this.$emit('onToast', { status: 'attention', body: 'Impossible de récupérer le résumé des commandes en mode hors-ligne' });
      }
      this.loadingSupplierIdPDF = null;
    },
    async getPasswords() {
      if (this.isOnline) {
        this.isLoadingPasswords = true;
        try {
          const response = await AppService.getPasswords();
          if (response.status === 200) {
            const bytes = await this.base64ToArrayBuffer(response.data);
            const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'passwords.xlsx';
            link.click();
          } else {
            this.$emit('onToast', { status: 'erreur', body: 'Une erreur s\'est produite lors de la récupération des mots de passe' });
          }
        } catch (err) {
          this.$emit('onToast', { status: 'erreur', body: 'Une erreur s\'est produite lors de la récupération des mots de passe' });
        }
      } else {
        this.$emit('onToast', { status: 'attention', body: 'Impossible de récupérer les mots de passe en mode hors-ligne' });
      }
      this.isLoadingPasswords = false;
    },
    base64ToArrayBuffer(base64) {
      const binaryString = window.atob(base64);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i += 1) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    },
    getFilteredSuppliers() {
      this.filteredSuppliers = this.search ? this.suppliers.filter((el) => el.supplierId.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || el.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) : this.suppliers;
    },
  },
  computed: {
    // filteredSuppliers() {
    //   return this.search ? this.suppliers.filter(el => el.supplierId.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || el.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) : this.suppliers;
    // },
    isOnline() {
      return this.$store.state.isOnline;
    },
  },
  async created() {
    await this.getSuppliers();
    this.getFilteredSuppliers();
  },
};
</script>
