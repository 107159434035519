<template>
  <div class="container-fluid p-interface">
    <template v-if="isLoading">
      <div class="row mt-5">
        <div class="col d-flex align-item-center justify-content-center mt-5">
          <span class="loader mt-5">Chargement des données</span>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row mb-3">
        <div class="col-12 mt-4 mb-2">
          <h2>Sélection client</h2>
        </div>
        <div class="col-12 col-sm-6">
          <form action="#" @submit.prevent="handleSearch()">
            <div class="input-group mb-3">
              <input type="search" class="form-control" placeholder="Numéro de client" aria-label="Recherche" v-model="search" :disabled="isFetchingOrderSummary" />
              <div class="input-group-append">
                <button class="btn btn-danger bg-cordier" type="submit" :disabled="!canSearch">
                  <template v-if="isFetchingOrderSummary">
                    <span class="loader-sm">&nbsp;</span>
                  </template>
                  <template v-else>
                    Rechercher
                  </template>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <template v-if="profile">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 mb-3">
            <h5 v-if="orderSummary">Client</h5>
            <div class="card customer-card" @click="orderSummary ? handleEditOrder() : handleNewOrder()">
              <div class="card-body">
                <h5 class="card-title color-cordier">{{profile.name}}</h5>
                <p class="card-text">{{profile.address}}<br>{{profile.postalCode}} - {{profile.city}}</p>
                <p class="card-text">{{profile.phone}}</p>
                <p class="card-text text-muted">TVA: {{profile.vat}}</p>
              </div>
            </div>
          </div>
          <div v-if="orderSummary" class="col-12 col-sm-6 col-md-4">
            <h5>Commande</h5>
            <div class="card" @click="handleEditOrder()">
              <div class="card-body">
                <h5 class="card-title color-cordier">{{orderSummary.articlesQty}} article<span v-if="parseInt(orderSummary.articlesQty) > 1">s</span></h5>
                <p class="card-text m-0">Commande passée le {{orderSummary.createdOn}}</p>
                <p v-if="orderSummary.modifiedOn" class="card-text text-muted">Modifiée le {{orderSummary.modifiedOn}}</p>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row">
          <div class="col-12 text-center text-muted">
            <p v-if="error" v-html="error"></p>
            <button v-if="error && isOnline" class="btn btn-danger bg-cordier" @click="fetchData()">Réessayer</button>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import AppService from '../../shared/services/AppService';
import NewOrderCommand from './commands/NewOrder-command';

export default {
  name: 'Customers',
  data() {
    return {
      search: '',
      isLoading: false,
      isFetchingOrderSummary: false,
      isFetchingOrderData: false,
      profile: null,
      orderSummary: null,
      error: null,
      isProperNewOrder: false,
    };
  },
  methods: {
    async getCustomers() {
      if (this.isOnline) {
        try {
          const response = await AppService.getCustomer(encodeURIComponent('%'));
          if (response.status === 200) {
            this.error = null;
            this.$store.commit('setCustomers', { data: response.data, date: response.headers.date });
          } else if (response.status === 410) {
            this.error = response.data;
          } else {
            this.error = 'Une erreur inattendue s\'est produite lors de la récupération des clients';
          }
        } catch (err) {
          this.error = 'Une erreur inattendue s\'est produite avec le serveur lors de la récupération des clients';
        }
      } else {
        this.error = 'Nous ne pouvons récupérer la liste des clients hors connexion';
      }
    },
    async getArticles() {
      if (this.isOnline) {
        try {
          const response = await AppService.getArticles(this.user.supplierId);
          if (response.status === 200) {
            this.error = null;
            this.$store.commit('setArticles', { data: response.data, date: response.headers.date });
          } else if (response.status === 410) {
            this.error = response.data;
          } else if (response.status === 404) {
            this.error = 'Nous n\'avons pas trouvé d\'articles pour votre compte fournisseur.';
          } else {
            this.error = this.canSearch ? 'Une erreur inattendue s\'est produite lors de la mise à jour de la liste d\'articles, mais des données sont présentes dans l\'application<br>Vous pouvez continuer à utiliser l\'application, nous tenterons d\'effectuer la mise à jour plus tard' : 'Une erreur inattendue s\'est produite lors de la récupération des articles';
          }
        } catch (err) {
          this.error = this.canSearch ? 'Une erreur inattendue s\'est produite lors de la mise à jour de la liste d\'articles, mais des données sont présentes dans l\'application<br>Vous pouvez continuer à utiliser l\'application, nous tenterons d\'effectuer la mise à jour plus tard' : 'Une erreur inattendue s\'est produite avec le serveur lors de la récupération des articles';
        }
      } else {
        this.error = this.canSearch ? 'Nous ne pouvons récupérer la liste des articles hors connexion, mais des données sont présentes dans l\'application<br>Vous pouvez continuer à utiliser l\'application, nous tenterons d\'effectuer la mise à jour plus tard' : 'Nous ne pouvons récupérer la liste des articles hors connexion';
      }
    },
    async fetchData() {
      this.isLoading = true;
      if (!this.customers) {
        await this.getCustomers();
      }
      await this.getArticles();
      this.isLoading = false;
      setTimeout(() => document.querySelector('input').focus(), 0);
    },
    async handleSearch() {
      this.isFetchingOrderSummary = true;
      this.profile = null;
      this.orderSummary = null;
      const customer = this.customers.find((el) => el.visitorId === this.search.toUpperCase());
      if (!customer) {
        this.error = 'Aucun client ne possède ce numéro';
      } else {
        await this.getOrderSummary(customer.visitorId);
        this.profile = customer;
      }
      this.isFetchingOrderSummary = false;
    },
    async getOrderSummary(visitorId) {
      this.orderSummary = null;
      if (this.isOnline) {
        try {
          const response = await AppService.getOrderSummary(visitorId, this.user.supplierId);
          if (response.status === 200) {
            this.orderSummary = response.data.orderId ? response.data : null;
          }
        } catch (err) {
          this.$emit('onToast', { status: 'erreur', body: 'Une erreur innatendue s\'est produite lors de la récupération de la commande' });
          this.$emit('onToast', { status: 'attention', body: 'Si vous continuez, une nouvelle commande sera créée.<br>Si une commande existe déjà pour ce client, <span class="font-weight-bold">les articles des deux commandes seront additionnés.</span>' });
        }
      } else {
        this.$emit('onToast', { status: 'erreur', body: 'La récupération des commandes existantes n\'a pas pu être effectuée en raison du mode hors-ligne.' });
        this.$emit('onToast', { status: 'attention', body: 'Si vous continuez, une nouvelle commande sera créée.<br>Si une commande existe déjà pour ce client, <span class="font-weight-bold">les articles des deux commandes seront additionnés.</span>' });
      }
    },
    async getOrder() {
      try {
        const response = await AppService.getOrder(this.orderSummary.orderId);

        if (response.status === 200) {
          return response.data;
        }
        return null;
      } catch (err) {
        return null;
      }
    },
    handleNewOrder() {
      this.isProperNewOrder = true;
      this.$store.commit('setWorkingCustomer', this.profile);
      this.$router.push('/customers/new-order');
    },
    async handleEditOrder() {
      this.isFetchingOrderData = true;
      this.isLoading = true;
      const order = await this.getOrder();
      if (order) {
        const orderCommand = new NewOrderCommand();
        orderCommand.orderId = order.orderId;
        orderCommand.fkVisitor = order.fkVisitor;
        order.orderLines.forEach((line) => {
          const article = orderCommand.orderLines.find((el) => el.articleId === line.fkArticle);
          if (article) {
            article.qtyWeek1 = line.qtyWeek1;
            article.qtyWeek2 = line.qtyWeek2;
            article.qtyWeek3 = line.qtyWeek3;
            article.qtyWeek4 = line.qtyWeek4;
            article.qtyWeek5 = line.qtyWeek5;
            article.qtyWeek6 = line.qtyWeek6;
          }
        });
        this.isProperNewOrder = true;
        this.$store.commit('setWorkingCustomer', this.profile);
        this.$router.push({ name: 'new-order', params: { editOrder: orderCommand } });
      } else {
        this.$emit('onToast', { status: 'erreur', body: 'Une erreur s\'est produite lors de la récupération de la commande.' });
        this.$emit('onToast', { status: 'attention', body: 'Si vous continuez, une nouvelle commande sera créée.<br>Si une commande existe déjà pour ce client, <span class="font-weight-bold">les articles des deux commandes seront additionnés.</span>' });
        this.handleNewOrder();
      }
      this.isFetchingOrderData = false;
      this.isLoading = false;
    },
  },
  computed: {
    isOnline() {
      return this.$store.state.isOnline;
    },
    user() {
      return this.$store.state.user;
    },
    customers() {
      return this.$store.state.customers;
    },
    articles() {
      return this.$store.state.articles;
    },
    canSearch() {
      return this.customers && this.articles;
    },
  },
  async created() {
    if (this.isOnline) {
      this.fetchData();
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'new-order' && !this.isProperNewOrder) {
      next(false);
    } else {
      next();
    }
  },
};
</script>
