<template>
  <div class="container-fluid p-interface">
    <div class="row mb-3">
      <div class="col-12 mt-4 mb-2">
        <h2>Export commandes client</h2>
      </div>
      <div class="col-12 col-sm-6">
        <form action="#" @submit.prevent="handleSearch()">
          <div class="input-group mb-3">
            <input type="search" class="form-control" placeholder="Numéro de client" aria-label="Recherche" v-model="search" />
            <div class="input-group-append">
              <button class="btn btn-danger bg-cordier" type="submit">
                <template v-if="isFetchingCustomer">
                  <span class="loader-sm">&nbsp;</span>
                </template>
                <template v-else>
                  Rechercher
                </template>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-if="profile">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title color-cordier">{{profile.name}}</h5>
              <p class="card-text">{{profile.address}}<br>{{profile.postalCode}} - {{profile.city}}</p>
              <p class="card-text">{{profile.phone}}</p>
              <p class="card-text text-muted">TVA: {{profile.vat}}</p>
            </div>
            <div class="card-footer">
              <div class="form-group">
                <label class="form-group-label">Email</label>
                <input class="form-control" type="email" v-model="profile.email" required maxlength="254" pattern="^((([a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-zA-Z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!hasOrders" class="row">
        <div class="col">
          <span class="text-muted">Ce client n'a pas passé de commande.</span>
        </div>
      </div>
      <div v-else class="row">
        <div class="col">
          <button class="btn btn-danger bg-cordier mr-3" @click="sendCheque(profile.email, profile.visitorId)"><span v-if="!isSendingEmail">Envoyer par email</span><span v-else class="loader-sm">&nbsp;</span></button>
          <button class="btn btn-danger bg-cordier" @click="getCheque(profile.visitorId)"><span v-if="!isGeneratingCheque">Télécharger</span><span v-else class="loader-sm">&nbsp;</span></button>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-12 text-center text-muted">
          <p v-if="error" v-html="error"></p>
          <button v-if="error && isOnline" class="btn btn-danger bg-cordier" @click="fetchData()">Réessayer</button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import AppService from '../../shared/services/AppService';

export default {
  name: 'AdminExports',
  data() {
    return {
      search: null,
      isFetchingCustomer: false,
      isGeneratingCheque: false,
      isSendingEmail: false,
      error: null,
      profile: null,
    };
  },
  methods: {
    async handleSearch() {
      this.profile = null;
      this.isFetchingCustomer = true;
      if (this.isOnline) {
        try {
          const response = await AppService.getCustomer(this.search);
          if (response.status === 200) {
            [this.profile] = response.data;
          } else if (response.status === 410) {
            this.error = response.data;
          } else {
            this.error = 'Une erreur inattendue s\'est produite lors de la récupération du client';
          }
        } catch (err) {
          this.error = 'Une erreur inattendue s\'est produite avec le serveur lors de la récupération du client';
        }
      } else {
        this.error = 'Nous ne pouvons récupérer le client hors connexion';
      }
      this.isFetchingCustomer = false;
    },
    async getCheque(visitorId) {
      this.isGeneratingCheque = true;
      if (this.isOnline) {
        try {
          const response = await AppService.getCheque(visitorId);
          if (response.status === 200) {
            const bytes = await this.base64ToArrayBuffer(response.data);
            const blob = new Blob([bytes], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = visitorId;
            link.click();
          } else {
            this.$emit('onToast', { status: 'erreur', body: 'Une erreur s\'est produite lors de la récupération du chèque de ristournes' });
          }
        } catch (err) {
          this.$emit('onToast', { status: 'erreur', body: 'Une erreur s\'est produite lors de la récupération du chèque de ristournes' });
        }
      } else {
        this.$emit('onToast', { status: 'attention', body: 'Impossible de récupérer le chèque de ristournes en mode hors-ligne' });
      }
      this.isGeneratingCheque = false;
    },
    base64ToArrayBuffer(base64) {
      const binaryString = window.atob(base64);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i += 1) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    },
    async sendCheque(email, visitorId) {
      this.isSendingEmail = true;
      if (this.isOnline) {
        const isValid = document.querySelector('input[type="email"]').checkValidity();
        if (isValid) {
          try {
            const response = await AppService.sendCheque(email, visitorId);
            if (response.status === 200) {
              this.$emit('onToast', { status: 'succès', body: 'Email envoyé avec succès !' });
            } else {
              this.$emit('onToast', { status: 'erreur', body: 'Une erreur s\'est produite lors de l\'envoi de l\'email' });
            }
          } catch (err) {
            this.$emit('onToast', { status: 'erreur', body: 'Une erreur s\'est produite lors de l\'envoi de l\'email' });
          }
        } else {
          this.$emit('onToast', { status: 'erreur', body: 'L\'adresse email est invalide' });
        }
      } else {
        this.$emit('onToast', { status: 'attention', body: 'Impossible d\'envoyer l\'email en mode hors-ligne' });
      }
      this.isSendingEmail = false;
    },
  },
  computed: {
    isOnline() {
      return this.$store.state.isOnline;
    },
    customers() {
      return this.$store.state.customers;
    },
    hasOrders() {
      return this.profile ? this.profile.orderQty > 0 : false;
    },
  },
};
</script>
