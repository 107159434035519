<template>
  <div id="app" class="full-height">
    <nav v-if="!isLoginScreen" class="navbar navbar-dark bg-cordier--dark border-bottom--primary fixed-top">
      <router-link v-if="isSuperAdmin" class="navbar-brand" to="/admin">
        <img src="./assets/logo-cordier-small.jpg" class="navbar-brand__logo" alt="Cordier logo">
      </router-link>
      <img v-else src="./assets/logo-cordier-small.jpg" class="navbar-brand__logo" alt="Cordier logo">
      <div class="d-flex align-items-center">
        <div class="d-inline-flex flex-column mr-4">
          <span class="text-muted small">Connecté en tant que</span>
          <span class="text-white font-weight-bold small text-right">{{user.name}}</span>
        </div>
        <button class="btn btn-danger bg-cordier btn-icon" @click="handleDisconnect()"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M 11 2 L 11 12 L 13 12 L 13 2 L 11 2 z M 9 2.4589844 C 4.943 3.7339844 2 7.523 2 12 C 2 17.523 6.477 22 12 22 C 17.523 22 22 17.523 22 12 C 22 7.523 19.057 3.7339844 15 2.4589844 L 15 4.5878906 C 17.931 5.7748906 20 8.644 20 12 C 20 16.418 16.418 20 12 20 C 7.582 20 4 16.418 4 12 C 4 8.643 6.069 5.7748906 9 4.5878906 L 9 2.4589844 z"></path></svg></button>
      </div>
    </nav>
    <router-view :class="{ 'p-interface--banner': shouldManualPush || !isOnline }" @onToast="pushToast($event)"/>
    <div v-if="!isOnline" class="bg-warning banner">
      <div class="col text-center small py-1 font-weight-bold">
        Application hors ligne<span v-if="ordersLength > 0"> - {{ordersLength}} commandes en file d'attente</span>
      </div>
    </div>
    <div v-if="shouldManualPush" class="bg-info text-white banner" @click="pushOrdersLoop()">
      <div class="col text-center small py-1 font-weight-bold">
        {{ordersLength}} commande<span v-if="ordersLength > 1">s</span> en file d'attente - Cliquez pour <span v-if="ordersLength > 1">les </span><span v-else>l'</span>envoyer.
      </div>
    </div>
    <ul v-if="!isLoginScreen && isSuperAdmin" class="nav bg-light border-top border-muted justify-content-around fixed-bottom">
      <li class="nav-item small">
        <router-link class="nav-link" to="/admin">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M 4 3 C 2.9045455 3 2 3.9045455 2 5 L 2 19 C 2 20.095455 2.9045455 21 4 21 L 20 21 C 21.095455 21 22 20.095455 22 19 L 22 5 C 22 3.9069372 21.093063 3 20 3 L 4 3 z M 4 5 L 20 5 L 20 19 L 4 19 L 4 5 z M 6 7 L 6 9 L 10 9 L 10 7 L 6 7 z M 12 7 L 12 17 L 14 17 L 14 7 L 12 7 z M 16 7 L 16 9 L 18 9 L 18 7 L 16 7 z M 6 11 L 6 13 L 10 13 L 10 11 L 6 11 z M 16 11 L 16 13 L 18 13 L 18 11 L 16 11 z M 6 15 L 6 17 L 10 17 L 10 15 L 6 15 z M 16 15 L 16 17 L 18 17 L 18 15 L 16 15 z"></path></svg>
          Tableau de bord
        </router-link>
      </li>
      <li class="nav-item small">
        <router-link class="nav-link" to="/suppliers">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M 12 3 C 9.8027056 3 8 4.8027056 8 7 C 8 9.1972944 9.8027056 11 12 11 C 14.197294 11 16 9.1972944 16 7 C 16 4.8027056 14.197294 3 12 3 z M 12 5 C 13.116414 5 14 5.8835859 14 7 C 14 8.1164141 13.116414 9 12 9 C 10.883586 9 10 8.1164141 10 7 C 10 5.8835859 10.883586 5 12 5 z M 14 12 C 12.906937 12 12 12.906937 12 14 C 10.255047 14 8.1871638 14.409783 6.4492188 15.095703 C 5.5802462 15.438663 4.7946961 15.84605 4.1660156 16.369141 C 3.5373351 16.892231 3 17.599384 3 18.5 L 3 21 L 12 21 L 12 22 C 12 23.093063 12.906937 24 14 24 L 22 24 C 23.093063 24 24 23.093063 24 22 L 24 14 C 24 12.906937 23.093063 12 22 12 L 14 12 z M 14 14 L 22 14 L 22 22 L 14 22 L 14 14 z M 12 16 L 12 19 L 5 19 L 5 18.5 C 5 18.434616 5.0675867 18.220535 5.4453125 17.90625 C 5.8230383 17.591965 6.4454882 17.245618 7.1816406 16.955078 C 8.6539455 16.373999 10.585953 16 12 16 z M 16 16 L 16 18 L 20 18 L 20 16 L 16 16 z"></path></svg>
          Fournisseurs
        </router-link>
      </li>
      <li class="nav-item small">
        <router-link class="nav-link" to="/goods">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M 12.027344 2 C 11.699344 2 11.392078 2.1616406 11.205078 2.4316406 L 6.6679688 9 L 2.0019531 9 C 1.6889531 9 1.3950781 9.1455313 1.2050781 9.3945312 C 1.0170781 9.6425312 0.95315625 9.964625 1.0351562 10.265625 L 3.5957031 19.53125 C 3.8347031 20.39525 4.6274375 21 5.5234375 21 L 9 21 L 9 19 L 5.5234375 19 L 3.3144531 11 L 16.8125 11 L 20.685547 11 L 20.408203 12 L 22.484375 12 L 22.962891 10.267578 C 23.045891 9.9665781 22.982922 9.6425313 22.794922 9.3945312 C 22.604922 9.1455313 22.310047 9 21.998047 9 L 17.339844 9 L 12.853516 2.4355469 C 12.667516 2.1635469 12.36025 2.001 12.03125 2 L 12.027344 2 z M 12.023438 4.765625 L 14.916016 9 L 9.0976562 9 L 12.023438 4.765625 z M 13 14 C 11.906937 14 11 14.906937 11 16 L 11 22 C 11 23.093063 11.906937 24 13 24 L 14 24 L 22 24 C 23.093063 24 24 23.093063 24 22 L 24 20 L 24 16 C 24 14.906937 23.093063 14 22 14 L 13 14 z M 13 16 L 22 16 L 22 20 L 22 22 L 14 22 L 13 22 L 13 19 L 20 19 L 20 17 L 13 17 L 13 16 z"></path></svg>
          Articles
        </router-link>
      </li>
      <li class="nav-item small">
        <router-link class="nav-link" to="/visitors">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M16.5 13c-1.2 0-3.07.34-4.5 1-1.43-.67-3.3-1-4.5-1C5.33 13 1 14.08 1 16.25V19h22v-2.75c0-2.17-4.33-3.25-6.5-3.25zm-4 4.5h-10v-1.25c0-.54 2.56-1.75 5-1.75s5 1.21 5 1.75v1.25zm9 0H14v-1.25c0-.46-.2-.86-.52-1.22.88-.3 1.96-.53 3.02-.53 2.44 0 5 1.21 5 1.75v1.25zM7.5 12c1.93 0 3.5-1.57 3.5-3.5S9.43 5 7.5 5 4 6.57 4 8.5 5.57 12 7.5 12zm0-5.5c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm9 5.5c1.93 0 3.5-1.57 3.5-3.5S18.43 5 16.5 5 13 6.57 13 8.5s1.57 3.5 3.5 3.5zm0-5.5c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2z"/></svg>
          Visiteurs
        </router-link>
      </li>
      <li class="nav-item small">
        <router-link class="nav-link" to="/orders">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M 4 3 L 4 15 L 2 15 L 2 18 C 2 19.654 3.346 21 5 21 L 18 21 C 19.64497 21 21 19.64497 21 18 L 21 3 L 4 3 z M 6 5 L 19 5 L 19 18 C 19 18.56503 18.56503 19 18 19 C 17.448 19 17 18.551 17 18 L 17 15 L 6 15 L 6 5 z M 8 7 L 8 9 L 10 9 L 10 7 L 8 7 z M 12 7 L 12 9 L 17 9 L 17 7 L 12 7 z M 8 11 L 8 13 L 10 13 L 10 11 L 8 11 z M 12 11 L 12 13 L 17 13 L 17 11 L 12 11 z M 4 17 L 15 17 L 15 18 C 15 18.35 15.060875 18.687 15.171875 19 L 5 19 C 4.449 19 4 18.551 4 18 L 4 17 z" ></path></svg>
          Commandes
        </router-link>
      </li>
    </ul>
    <template v-if="toasts">
      <div class="toasts-container">
        <Toast v-for="(toast, index) of toasts" :key="index" :toast="toast" :index="index" @onCloseToast="handleCloseToast($event)"/>
      </div>
    </template>
  </div>
</template>

<script>
import Toast from './shared/components/Toast.vue';
import AppService from './shared/services/AppService';

export default {
  name: 'App',
  components: {
    Toast,
  },
  data() {
    return {
      toasts: [],
      isLooping: false,
    };
  },
  methods: {
    handleDisconnect() {
      localStorage.removeItem('user');
      this.$router.replace('/login');
    },
    async pushOrders() {
      if (!this.isOnline) {
        throw new Error('Fail');
      }
      try {
        this.$store.commit('setProcessingOrderIndex', this.ordersLength - 1);
        const response = await AppService.createOrder(this.ordersToPush[this.ordersLength - 1]);
        if (response.status === 200) {
          this.$store.commit('removePushedOrder');
        } else {
          this.$store.commit('setProcessingOrderIndex', null);
          throw new Error('Gone');
        }
      } catch (err) {
        if (err.message === 'Gone') {
          throw err;
        }
        this.$store.commit('setProcessingOrderIndex', null);
        throw new Error('Fail');
      }
    },
    async pushOrdersLoop() {
      this.isLooping = true;
      const length = this.ordersLength;
      do {
        try {
          // eslint-disable-next-line
          await this.pushOrders();
        } catch (err) {
          if (err.message !== 'Gone') {
            break;
          }
          this.$store.commit('removePushedOrder');
          const customerName = this.getCurrentCustomerOrder();
          this.pushToast({ status: 'attention', body: `Une erreur s'est produite lors du transfert de la commande du client ${customerName}.<br>Celle-ci a bien été reçue par le serveur mais sera traitée ultérieurement.` });
        }
      } while (this.ordersLength > 0);
      this.isLooping = false;
      if (this.ordersLength === 0) {
        this.pushToast({ status: 'succès', body: length > 1 ? 'L\'ensemble des commandes a été envoyé avec succès' : 'Commande envoyée avec succès' });
      }
    },
    pushToast(toast) {
      this.toasts.push(toast);
    },
    handleCloseToast(index) {
      this.toasts.splice(index, 1);
    },
    getCurrentCustomerOrder() {
      return this.$store.state.customers.find((el) => el.visitorId === this.ordersToPush[this.ordersLength - 1].fkVisitor).name;
    },
  },
  computed: {
    isAdmin() {
      return this.$store.state.isAdmin === 1 || this.$store.state.isAdmin === 2;
    },
    isSuperAdmin() {
      return this.$store.state.isAdmin === 2;
    },
    user() {
      return this.$store.state.user;
    },
    isLoginScreen() {
      return this.$route.name === 'login';
    },
    isOnline() {
      return this.$store.state.isOnline;
    },
    ordersToPush() {
      return this.$store.state.ordersToPush;
    },
    ordersLength() {
      return this.$store.state.ordersToPush.length;
    },
    shouldManualPush() {
      return this.isOnline && this.ordersLength > 0 && !this.$store.state.processingOrderIndex;
    },
  },
  watch: {
    ordersLength(newVal, oldVal) {
      if (newVal > oldVal && this.isOnline && !this.isLooping) {
        this.pushOrdersLoop();
      }
    },
    isOnline(val) {
      if (val && this.ordersLength) {
        this.pushOrdersLoop();
      }
    },
  },
  created() {
    window.addEventListener('offline', () => {
      this.$store.commit('setOnlineState', false);
    });
    window.addEventListener('online', () => {
      this.$store.commit('setOnlineState', true);
    });

    const storedOrders = localStorage.getItem('ordersToPush');

    if (storedOrders) {
      const storedOrdersJSON = JSON.parse(storedOrders);
      localStorage.removeItem('ordersToPush');
      storedOrdersJSON.forEach((item) => this.$store.commit('pushNewOrder', item));
    }

    const storedUser = localStorage.getItem('user');

    if (storedUser) {
      this.$store.commit('setUser', JSON.parse(storedUser));
      if (this.isSuperAdmin) {
        this.$router.replace('/admin');
      } else {
        this.$router.replace(this.isAdmin ? '/admin-exports' : '/customers');
      }
    } else {
      this.$router.replace('/login');
    }
  },
};
</script>
