<template>
  <div class="container-fluid p-interface">
    <div class="row mb-3">
      <div class="col-12 mt-4 mb-2">
        <h2 class="mb-0">Commandes</h2>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        <h4 class="font-weight-normal">A envoyer</h4>
      </div>
      <div class="col-auto">
        <button class="btn btn-danger btn-sm bg-cordier" @click="handlePushOrdersAction()" :disabled="!shouldManualPush" >Envoyer maintenant</button>
      </div>
    </div>
    <div v-if="orders" class="row mb-4">
      <div class="col">
        <table class="table table-striped">
          <thead>
            <th class="small font-weight-bold bg-light">Client</th>
            <th class="small font-weight-bold bg-light">Adresse</th>
            <th class="small font-weight-bold bg-light">TVA</th>
            <th class="small font-weight-bold bg-light">Articles</th>
            <th class="small font-weight-bold bg-light">Status</th>
          </thead>
          <tbody>
            <CartOrderLine v-for="(order, index) of orders" :key="order.fkVisitor + index" :order="order" :index="index" />
          </tbody>
        </table>
      </div>
    </div>
    <div v-else class="row mb-4">
      <div class="col-12 text-muted">
        <p>Aucune commande en attente d'envoi</p>
      </div>
    </div>
  </div>
</template>

<script>
import CartOrderLine from './components/CartOrderLine.vue';

export default {
  name: 'Cart',
  components: {
    CartOrderLine,
  },
  methods: {
    handlePushOrdersAction() {
      this.$emit('onPushRequest');
    },
  },
  computed: {
    orders() {
      return this.$store.state.ordersToPush;
    },
    isOnline() {
      return this.$store.state.isOnline;
    },
    shouldManualPush() {
      return this.isOnline ? !this.$store.state.processingOrderIndex && !!this.orders.length : false;
    },
  },
};
</script>
