<template>
  <div class="container-fluid p-interface p-interface--bottom">
    <template v-if="isLoading">
      <div class="row mt-5">
        <div class="col d-flex align-item-center justify-content-center mt-5">
          <span class="loader mt-5">Chargement des données</span>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row mb-3">
        <div class="col-12 mt-4 mb-2">
          <h2 class="mb-0">Visiteurs</h2>
        </div>
        <div class="col-12 col-sm-6">
          <form action="#" @submit.prevent="getFilteredVisitors()">
            <div class="input-group mb-3">
              <input type="search" class="form-control" aria-label="Recherche" v-model="search" />
              <div class="input-group-append">
                <button class="btn btn-danger bg-cordier" type="submit">
                  Rechercher
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <template v-if="visitors.length > 0">
        <div v-if="filteredVisitors.length > 0" class="row">
          <div class="col-12">
            <table class="table table-striped table-hover">
              <thead>
                <th class="small font-weight-bold bg-light">Num. Client</th>
                <th class="small font-weight-bold bg-light">Nom</th>
                <th class="small font-weight-bold bg-light">TVA</th>
                <th class="small font-weight-bold bg-light">Email</th>
                <th class="small font-weight-bold bg-light">Email utilisé</th>
                <th class="small font-weight-bold bg-light">Nbr. Commandes</th>
              </thead>
              <tbody>
                <tr v-for="item of filteredVisitors" :key="item.supplierId">
                  <td class="small py-2">{{item.visitorId}}</td>
                  <td class="small py-2">{{item.name}}</td>
                  <td class="small py-2">{{item.vat}}</td>
                  <td class="small py-2">{{item.email}}</td>
                  <td class="small py-2">{{item.usedEmail}}</td>
                  <td class="small py-2">{{item.orderQty}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-12 text-center text-muted">
            <p>Aucun visiteur ne correspond à votre recherche</p>
          </div>
        </div>
      </template>
      <template v-if="visitors.length === 0 && !error">
        <div class="row">
          <div class="col-12 text-center text-muted">
            <p>Il semblerait que cette liste soit vide.<br>Importez une liste de visiteurs depuis le tableau de bord.</p>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row">
          <div class="col-12 text-center text-muted">
            <p>{{error}}</p>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import AppService from '../../shared/services/AppService';

export default {
  name: 'Suppliers',
  data() {
    return {
      search: null,
      visitors: [],
      error: null,
      isLoading: false,
      filteredVisitors: [],
    };
  },
  methods: {
    async getVisitors() {
      this.isLoading = true;
      if (this.isOnline) {
        try {
          const response = await AppService.getCustomer(encodeURIComponent('%'));

          if (response.status === 200) {
            this.visitors = response.data;
            this.error = null;
          } else {
            this.visitors = [];
            this.error = 'Une erreur est survenue lors de la récupération des visiteurs';
          }
        } catch (err) {
          this.visitors = [];
          this.error = 'Une erreur innatendue est survenue avec notre serveur';
        }
      } else {
        this.visitors = [];
        this.error = 'Impossible de récupérer les visiteurs en mode hors-ligne';
      }
      this.isLoading = false;
    },
    getFilteredVisitors() {
      this.filteredVisitors = this.search ? this.visitors.filter((el) => el.visitorId.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || el.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || el.vat.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) : this.visitors;
    },
  },
  computed: {
    // filteredVisitors() {
    //   return this.search ? this.visitors.filter(el => el.visitorId.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || el.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) : this.visitors;
    // },
    isOnline() {
      return this.$store.state.isOnline;
    },
  },
  async created() {
    await this.getVisitors();
    this.getFilteredVisitors();
  },
};
</script>
